import type { Language } from "@/src/types/languages";

interface TranslationMap {
  [key: string]: Partial<Record<Language, string>>;
}

export const translations: TranslationMap = {
  "Wagering requirements on withdrawals": {
    sv: "Omsättningskrav på uttag",
  },
  "Wagering requirements on deposit": {
    sv: "oms.krav på insättning",
  },
  "If deposit requirements": {
    sv: "oms.krav insättning",
  },
  "Withdrawal attempt": {
    sv: "Uttagsförsök",
  },
  Speed: {
    sv: "Hastighet",
  },
  "Find Starbust (Speed)": {
    sv: "Hitta Starburst (Hastighet)",
  },
  "Average chat response time": {
    sv: "Genomsnittlig svarstid chatt",
  },
  "Chat response time": {
    sv: "svarstid chatt",
  },
  "Response time from live chat (avg)": {
    sv: "Responstid från live chat (avg)",
  },
  "Rank from other review sites": {
    sv: "Rank från andra jämförelsesidor",
  },
  One: {
    sv: "Ett",
  },
  Two: {
    sv: "Två",
  },
  Three: {
    sv: "Tre",
  },
  Sort: {
    sv: "Sortera",
  },
  Direct: {
    sv: "Direkt",
  },
  "Show more": {
    sv: "Visa mer",
  },
  "Show less": {
    sv: "Visa mindre",
  },
  "Load more casinos": {
    sv: "Ladda fler casinon",
    no: "Vis flere casinoer",
  },
  "View less casinos": {
    sv: "Se färre casinon",
  },
  "Visit casino": {
    sv: "Till casinot",
    no: "Til casinoet",
  },
  Visit: {
    sv: "Besök",
  },
  "Proceed to": {
    sv: "Vidare till",
    no: "Videre til",
  },
  "Hot!": {
    sv: "Hett nu!",
  },
  "New!": {
    sv: "Nytt!",
  },
  "Popular!": {
    sv: "Populär!",
  },
  "Crispy!": {
    sv: "Krispigt!",
  },
  "Remember that...": {
    sv: "Tänk på att...",
  },
  "Did you know?": {
    sv: "Visste du att?",
  },
  Source: {
    sv: "Källa",
  },
  "Read news": {
    sv: "Läs nyheten",
  },
  "Read more": {
    sv: "Läs mer",
  },
  Articles: {
    sv: "Artiklar",
  },
  "Found no posts": {
    sv: "Fant inga artiklar",
  },
  "Last updated": {
    sv: "Senast uppdaterad",
  },
  "Table of contents": {
    sv: "Innehåll",
  },
  "Content editor": {
    sv: "Innehållsansvarig",
  },
  About: {
    sv: "Om",
  },
  "Contact us": {
    sv: "Kontakt oss",
  },
  Contact: {
    sv: "Kontakt",
  },
  "Page does not exist": {
    sv: "Sidan finns ej",
  },
  "Oops.. something went wrong": {
    sv: "Oops.. nu blev något fel",
  },
  Search: {
    sv: "Sök",
  },
  "Thank you for visiting": {
    sv: "Tack för att du besökte",
  },
  "Click here": {
    sv: "Klicka här",
  },
  "if you aren't forwarded to": {
    sv: "om du inte skickas vidare till",
  },
  "Casino has english license": {
    sv: "Casinot har svensk licens",
    ca: "Casino has canadian license",
  },
  "Casino open for english players": {
    sv: "Casinot öppen för svenska spelare",
    ca: "Casino open for canadian players",
    in: "Casino open for indian players",
  },
  Ratings: {
    sv: "Omdöme",
  },
  "Our rating": {
    sv: "Vårt betyg",
  },
  "Other ratings": {
    sv: "Andras betyg",
  },
  Bonus: {
    sv: "Bonus",
  },
  Payments: {
    sv: "Betalningar",
  },
  Support: {
    sv: "Kundtjänst",
  },
  "Games Experience": {
    sv: "Spelupplevelse",
  },
  "Games Sortiment": {
    sv: "Spelutbud",
  },
  Trust: {
    sv: "Tillit",
  },
  "English license": {
    sv: "Svensk licens",
    ca: "Canadian license",
  },
  "No English license": {
    sv: "Ingen svensk licens",
    ca: "No Canadian license",
  },
  "Casino closed": {
    sv: "Stängt casino",
  },
  "Games offered": {
    sv: "Spelutbud",
  },
  "Offered by": {
    sv: "Sponsras av",
  },
  "Number of games": {
    sv: "Antal spel",
  },
  "Tax free winnings": {
    sv: "Skattefria vinster",
  },
  License: {
    sv: "Licens",
  },
  "Bonus license": {
    sv: "Bonuslicens",
  },
  "Game providers": {
    sv: "Spelleverantörer",
  },
  "Mobile casino": {
    sv: "Mobilcasino",
  },
  "Live casino": {
    sv: "Livecasino",
  },
  "Fast registration": {
    sv: "Snabbregistrering",
  },
  "E-legitimation": {
    sv: "E-legitimation",
  },
  "Deposit options": {
    sv: "Insättninger via",
  },
  "Withdrawal methods": {
    sv: "Uttagsmetoder",
  },
  "Deposit methods": {
    sv: "Insättningsmetoder",
  },
  "Minimum deposit": {
    sv: "Minsta insättning",
  },
  "FS no deposit": {
    sv: "FS utan insättning",
  },
  Email: {
    sv: "E-mail",
  },
  "Send email": {
    sv: "Maila",
  },
  Phone: {
    sv: "Telefon",
  },
  "Live chat": {
    sv: "Livechatt",
  },
  Telephone: {
    sv: "Telefon",
  },
  Website: {
    sv: "Hemsida",
  },
  To: {
    sv: "Till",
    no: "Til",
    en: "To",
  },
  "New customers. 18+. Gamble responsibly.": {
    no: "Kun tilgjengelig for nye kunder. 18+. Spill ansvarlig.",
  },
  "Affiliate disclosure": {
    sv: "Annonsinformation",
    no: "Annonseinformasjon",
  },
  "No Account": {
    sv: "Inget konto",
  },
  "Without account": {
    sv: "Utan konto",
  },
  "My profile": {
    sv: "Min profil",
  },
  "Log in": {
    sv: "Logga in",
  },
  "No turnover": {
    sv: "Utan omsättningskrav",
  },
  Turnover: {
    sv: "Omsättningskrav",
  },
  "Wagering req.": {
    sv: "Oms.krav",
  },
  "Deposit wagering": {
    sv: "Omsättkningskav på insättning",
  },
  "Wagering games": {
    sv: "Oms.krav spel",
  },
  "No bonus": {
    sv: "Inga bonus",
  },
  "out of": {
    sv: "utav",
  },
  Without: {
    sv: "Utan",
  },
  No: {
    sv: "Inga",
    no: "Ingen",
  },
  No2: {
    en: "No",
    sv: "Nej",
  },
  No3: {
    en: "No",
    sv: "Ingen",
  },
  Yes: {
    sv: "Ja",
  },
  Founded: {
    sv: "Lanseringsår",
  },
  casinos: {
    sv: "casinon",
  },
  "Casino bonus": {
    sv: "Casinobonus",
  },
  "Sports bonus": {
    sv: "Sportbonus",
  },
  "Bonus wagering requirements": {
    sv: "Omsättningskrav på bonus",
  },
  "Bonus not available": {
    sv: "Bonus ej tillgänglig",
  },
  "Spin wagering requirements": {
    sv: "Omsättningskrav på spin",
  },
  "Free spins not available": {
    sv: "Free spins ej tillgängligt",
  },
  "Min. odds": {
    sv: "Minsta odds",
  },
  Withdrawal: {
    sv: "Uttag",
  },
  "Withdrawal Time": {
    sv: "Uttagstid",
  },
  withdrawal_Time: {
    sv: "uttagstid",
  },
  "Withdrawal Time estimated": {
    sv: "Uttagstid (Estimerad)",
  },
  "(tested)": {
    sv: "(vårt test)",
  },
  "Withdrawal Fee": {
    sv: "Uttagsavgift",
  },
  "Min Withdrawal": {
    sv: "Minsta uttag",
  },
  "Min deposit": {
    sv: "Minsta insättning",
  },
  "Bonus min deposit": {
    sv: "Bonus min insättning",
  },
  Deposit: {
    sv: "Insättning",
  },
  hour: {
    sv: "tim",
  },
  hours: {
    sv: "tim",
  },
  h: {
    sv: "h",
  },
  minutes: {
    sv: "minuter",
  },
  min: {
    sv: "min",
  },
  seconds: {
    sv: "sekunder",
  },
  sec: {
    sv: "sek",
  },
  News: {
    sv: "Nyheter",
  },
  Archive: {
    sv: "Arkiv",
  },
  January: {
    sv: "Januari",
  },
  February: {
    sv: "Februari",
  },
  March: {
    sv: "Mars",
  },
  April: {
    sv: "April",
  },
  May: {
    sv: "Maj",
  },
  June: {
    sv: "Juni",
  },
  July: {
    sv: "Juli",
  },
  August: {
    sv: "Augusti",
  },
  September: {
    sv: "September",
  },
  October: {
    sv: "Oktober",
  },
  November: {
    sv: "November",
  },
  December: {
    sv: "December",
  },
  Result: {
    sv: "Resultat",
  },
  "Facts about": {
    sv: "Fakta om",
  },
  "More info": {
    sv: "Mer info",
  },
  Facts: {
    sv: "Fakta",
  },
  "Bonus game": {
    sv: "Bonusspel",
  },
  Progressive: {
    sv: "Progressiv",
  },
  allgames: {
    sv: "allaspel",
  },
  "Up to": {
    sv: "Upp till",
  },
  Oddsbonus: {
    sv: "Oddsbonus",
  },
  "Next draw": {
    sv: "Nästa dragning",
  },
  "Sitemap for": {
    sv: "Webbplatskarta över",
  },
  "Livecasino & Table Games": {
    sv: "Livecasino/Bordsspel",
  },
  "Odds & Betting": {
    sv: "Odds/Betting",
  },
  Bingo: {
    sv: "Bingo",
  },
  Lotto: {
    sv: "Lotto",
  },
  Slots: {
    sv: "Slots",
  },
  Horses: {
    sv: "Trav",
  },
  Sports: {
    sv: "Sport",
  },
  Fantazy: {
    sv: "Fantazy",
  },
  Baccarat: {
    sv: "Baccarat",
  },
  Roulette: {
    sv: "Roulette",
  },
  Blackjack: {
    sv: "Blackjack",
  },
  Livecasino: {
    sv: "Livecasino",
  },
  Read: {
    sv: "Läs",
  },
  Review: {
    sv: "Recension",
  },
  ReadReview: {
    sv: "Läs recension",
  },
  "Signup to our newsletter": {
    sv: "Registrera dig för nyhetsbrev",
  },
  "Shares license with": {
    sv: "Delar licensen med",
  },
  Owner: {
    sv: "Ägare",
  },
  speedExperience: {
    sv: "Hastighet (hitta starburst)",
  },
  speedExperience_caption: {
    sv: "(hitta starburst)",
  },
  livechatExperience: {
    sv: "Svarstid livechatt",
  },
  livechatExperience_caption: {
    sv: "livechatt",
  },
  withdrawalExperience: {
    sv: "Uttagstid",
  },
  emailExperience: {
    sv: "Email svarstid",
  },
  emailExperience_caption: {
    sv: "email",
  },
  "Our experience": {
    sv: "Vår upplevelse",
  },
  Average: {
    sv: "Medel",
  },
  Good: {
    sv: "Bra",
  },
  Bad: {
    sv: "Dåligt",
  },
  "Review from the site": {
    sv: "Omdöme från",
  },
  "has responded": {
    sv: "har svarat",
  },
  "has not responded": {
    sv: "har ej svarat",
  },
  "days ago": {
    sv: "Dagar sedan",
  },
  "hours ago": {
    sv: "Timmar sedan",
  },
  "minutes ago": {
    sv: "Minuter sedan",
  },
  "Just now": {
    sv: "Just nu",
  },
  "user comments": {
    sv: "omdömen",
  },
  "We’ve done [totalRoundCount] from [totalOperators] different casinos. Out of all tests the median value is [medianRoundScore], where as [rankDetailsName] has an avg value of [rankDetailsScore], which puts them in position [rankDetailsRank]":
    {
      sv: "Vi har gjort [totalRoundCount] test hos [totalOperators] olika casinon. Testen visar att medianen är [medianRoundScore], medan [rankDetailsName] har [rankDetailsScore] i genomsnitt, vilket placerar casinot på plats [rankDetailsRank]",
    },
  "Approved by": {
    sv: "Granskad av",
  },
  "Written by": {
    sv: "Skriven av",
  },
  "Our goal": {
    sv: "Vårt mål",
  },
  "Recent changes": {
    sv: "Senaste ändringar",
  },
  "Median value": {
    sv: "Medianvärde",
  },
  "Amount tested casinos": {
    sv: "Antal casinon testade",
  },
  "Best results": {
    sv: "Bästa resultat",
  },
  "overall rating title": {
    sv: "betyg",
  },
  "Comparison List": {
    sv: "Jämförelselista",
  },
  position: {
    sv: "har placering",
  },
  "avg value": {
    sv: "avg värde",
  },
  "amount tests": {
    sv: "Antal test",
  },
  "amount withdrawals": {
    sv: "Antal uttag med",
  },
  place: {
    sv: "plats",
  },
  "rank of": {
    sv: "plats av",
  },
  back: {
    sv: "Tillbaka",
  },
  share: {
    sv: "Dela",
  },
  "your stats": {
    sv: "In statistik",
  },
  "Total Prize Pool": {
    sv: "Prispott",
  },
  "Total Free Spins": {
    sv: "Antal free spins",
  },
  "Tournament Type": {
    sv: "Typ av turnering",
  },
  "Monthly Tournament": {
    sv: "Månadsturnering",
  },
  Monthly: {
    sv: "Månadsturnering",
  },
  "Daily Tournament": {
    sv: "Dagligturnering",
  },
  Daily: {
    sv: "Dagligturnering",
  },
  Play: {
    sv: "Spela",
  },
  Close: {
    sv: "Stäng",
  },
  "More Tournaments": {
    sv: "Fler turneringar",
  },
  "Featured Tournaments": {
    sv: "Utvalda turneringar",
  },
  "Frequently Asked Questions": {
    sv: "Vanliga frågor",
  },
  "Similar Providers": {
    sv: "Liknande leverantörer",
  },
  "More about": {
    sv: "Mer om",
  },
  "Live Tournaments": {
    sv: "Turneringar",
  },
  Upcoming: {
    sv: "Kommande",
  },
  Completed: {
    sv: "Slutförd",
  },
  Prize: {
    sv: "Pris",
  },
  Days: {
    sv: "Dagar",
  },
  Hours: {
    sv: "Timmar",
  },
  Minutes: {
    sv: "Minuter",
  },
  "Play for free": {
    sv: "Spela gratis",
  },
  Statistics: {
    sv: "Statistik",
  },
  Score: {
    sv: "Poäng",
  },
  Rank: {
    sv: "Plats",
  },
  Spins: {
    sv: "Spins",
  },
  Winners: {
    sv: "Vinnare",
  },
  "1st prize": {
    sv: "1:a pris",
  },
  "2nd prize": {
    sv: "2:a pris",
  },
  "3rd prize": {
    sv: "3:a pris",
  },
  points: {
    sv: "poäng",
  },
  "Be the first to compete!": {
    sv: "Var först med att tävla!",
  },
  "Your points will appear here after you run out of spins. Updated every five minutes.": {
    sv: "Dina poäng kommer att synas här efter att du har slut på spins. Uppdateras var femte minut.",
  },
  "Once you have no more spins, your score will be added within 5 minutes.": {
    sv: "När du har slut på spins kommer dina poäng läggas till inom 5 minuter.",
  },
  "Your score: [score]\nThe tournament ends on [endDate] - Good luck!": {
    sv: "Dina poäng: [score]\nTurneringen slutar den [endDate] - Lycka till!",
  },
  "Congratulations, you have [position] place!": {
    sv: "Grattis till [position] platsen!",
  },
  "Your score: [score]": {
    sv: "Dina poäng: [score]",
  },
  "End of tournament!": {
    sv: "Slutsnurrat!",
  },
  "Your points: [score]": {
    sv: "Dina poäng: [score]",
  },
  "End spinning! Play again TOMORROW": {
    sv: "Slutsnurrat! Spela vidare om TOMORROW",
  },
  "You currently hold [position] place.": {
    sv: "Du har just nu plats [position].",
  },
  "Back to tournaments": {
    sv: "Tillbaka till turneringarna",
  },
  "Best casinos to play": {
    sv: "Bästa casinon att spela på",
  },
  "Play Tournament": {
    sv: "Spela turnering",
  },
  "Check Leaderboard": {
    sv: "Kolla ledartavlan",
  },
  "Tournament Not Live Yet": {
    sv: "Turneringen är inte live än",
  },
  "Please provide the following information to be able to play our Tournaments:": {
    sv: "Vänligen ange följande uppgifter för att delta i våra turneringar:",
  },
  "Unique user name": {
    sv: "Unikt användarnamn",
  },
  "Age consent": {
    sv: "Födelsedatum",
  },
  "Legal consent": {
    sv: "Juridiskt samtycke",
  },
  "Valid phone number (to receive earnings)": {
    sv: "Giltigt telefonnummer (För att ta emot vinster)",
  },
  "Accept terms & conditions": {
    sv: "Acceptera villkoren",
  },
  "Your profile is all set! Go to": {
    sv: "Din profil är redo! Gå till",
  },
  Tournaments: {
    sv: "Turneringarna",
  },
  Name: {
    sv: "Namn",
  },
  Username: {
    sv: "Användarnamn",
  },
  "Date of Birth": {
    sv: "Födelsedatum",
  },
  "I am not registered to Spelpaus": {
    sv: "Jag är inte registrerad hos Spelpaus",
  },
  "I accept": {
    sv: "Jag godkänner",
  },
  "Casinofeber terms & conditions": {
    sv: "Casinofebers regler & villkor",
  },
  Required: {
    sv: "Obligatoriskt fält",
  },
  "Required to receive earnings": {
    sv: "Obligatoriskt för att ta emot vinster",
  },
  "Save Changes": {
    sv: "Spara ändringar",
  },
  "Joined on": {
    sv: "Kontot skapat",
  },
  "Log out": {
    sv: "Logga ut",
  },
  "Edit profile": {
    sv: "Profilinställningar",
  },
  "I consent to receiving newsletters": {
    sv: "Jag samtycker till att ta emot nyhetsbrev",
  },
  "Tournaments are only available to those who have consented to receiving newsletters": {
    sv: "Turneringar endast tillgängliga för de som godkänt nyhetsbrev",
  },
  "Auth popup title": {
    sv: "Spela vår gratisturnering.",
  },
  "Auth popup user not verified text": {
    sv: "För att delta i turneringarna behöver du ange e-postadress, födelsedatum, namn och telefonnummer i dina profilinställningar.",
  },
  "Auth popup no marketing consent text": {
    sv: "För att delta i turneringarna behöver du samtycka till vårt nyhetsbrev.",
  },
  "Auth popup not authenticated": {
    sv: "För att spela behöver du vara inloggad och ha en verifierad e-mailadress.",
  },
} as const;

export function getTranslatedText(
  key: string,
  varsOrLang: Record<string, string | number> | Language = {},
  vars?: Record<string, string | number>,
): string {
  const lang = typeof varsOrLang === "string" ? varsOrLang : "sv";
  const variables = typeof varsOrLang === "string" ? vars || {} : varsOrLang;

  const translation = translations[key]?.[lang] || key;
  let result = translation;

  Object.entries(variables).forEach(([key, value]) => {
    result = result.replace(`[${key}]`, String(value));
  });

  return result;
}
